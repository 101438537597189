var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-md-12" }, [
      _c("div", { staticClass: "card" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "card-body table-responsive table-full-width" },
          [
            _c(
              "el-table",
              { attrs: { data: _vm.tableData } },
              [
                _c("el-table-column", {
                  attrs: { label: "Id", property: "id", width: "100px" },
                }),
                _c("el-table-column", {
                  attrs: { label: "Name", property: "name" },
                }),
                _c("el-table-column", {
                  attrs: { label: "Country", property: "country" },
                }),
                _c("el-table-column", {
                  attrs: { label: "City", property: "city" },
                }),
                _c("el-table-column", {
                  attrs: { label: "Salary", property: "salary" },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "col-md-12" }, [
      _c("div", { staticClass: "card card-plain" }, [
        _vm._m(1),
        _c(
          "div",
          { staticClass: "card-body table-responsive table-full-width" },
          [
            _c(
              "el-table",
              { attrs: { data: _vm.tableData } },
              [
                _c("el-table-column", {
                  attrs: { label: "Id", property: "id", width: "100px" },
                }),
                _c("el-table-column", {
                  attrs: { label: "Name", property: "name" },
                }),
                _c("el-table-column", {
                  attrs: { label: "Salary", property: "salary" },
                }),
                _c("el-table-column", {
                  attrs: { label: "Country", property: "country" },
                }),
                _c("el-table-column", {
                  attrs: { label: "City", property: "city" },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "col-md-12" }, [
      _c("div", { staticClass: "card" }, [
        _vm._m(2),
        _c(
          "div",
          { staticClass: "card-body table-responsive table-full-width" },
          [
            _c(
              "el-table",
              {
                attrs: {
                  "row-class-name": _vm.tableRowClassName,
                  data: _vm.tableData,
                },
              },
              [
                _c("el-table-column", {
                  staticClass: "success",
                  attrs: { label: "Id", property: "id", width: "100px" },
                }),
                _c("el-table-column", {
                  attrs: { label: "Name", property: "name" },
                }),
                _c("el-table-column", {
                  staticClass: "danger",
                  attrs: { label: "Salary", property: "salary" },
                }),
                _c("el-table-column", {
                  staticClass: "info",
                  attrs: { label: "Country", property: "country" },
                }),
                _c("el-table-column", {
                  attrs: { label: "City", property: "city" },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("h5", { staticClass: "title" }, [_vm._v("Simple table")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("h4", { staticClass: "title" }, [_vm._v("Table on Plain Background")]),
      _c("p", { staticClass: "category" }, [
        _vm._v("Here is a subtitle for this table"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("h4", { staticClass: "title" }, [_vm._v("Regular Table with Colors")]),
      _c("p", { staticClass: "category" }, [
        _vm._v("Here is a subtitle for this table"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }